<template>
  <div class="row">
    <div class="col-lg-12">
      <List :account="account"></List>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import List from "./comps/List.vue";

export default {
  name: "SiteCookies",

  props: {
    account: { type: Object, required: true }
  },

  components: {
    List
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Cookies" }]);
  }
};
</script>

<style></style>
