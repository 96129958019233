<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Cookies encontrados
        </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          Lista de todos os cookies do seu site
        </span>
      </h3>
      <div class="card-toolbar">
        <cookiesModal
          :account="account"
          :categories="categories"
          :callback="getList"
          content="<p><h6>Adição de Cookies</h6> Você pode inserir de forma manual todos os cookies que desejar.</p>"
          v-tippy
        ></cookiesModal>
        <!-- <button class="btn  font-weight-bolder font-size-sm py-3 px-14 btn-info mr-5">Criar Cookie</button> -->
        <button
          class="btn  font-weight-bolder font-size-sm py-3 px-14 text-uppercase"
          :class="{
            'btn-success': !checkHashes,
            'btn-outline-primary action-disabled': checkHashes
          }"
          @click="saveItems"
          content="<p><h6>Salvar e Publicar</h6> Ao executar essa ação será criada uma nova versão de cookies para seu banner de consentimentos.</p>"
          v-tippy
        >
          Salvar e Publicar
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table
          class="table table-head-custom table-vertical-center"
          id="kt_advance_table_widget_1"
        >
          <thead>
            <tr class="text-left">
              <th style="min-width: 100px">Cookie</th>
              <th style="min-width: 150px">Provedor</th>
              <th style="min-width: 100px">Descrição</th>
              <th style="min-width: 150px">Categoria</th>
              <th style="min-width: 100px">Expiração</th>
              <th style="min-width: 100px">Excluir</th>
            </tr>
          </thead>
          <tbody v-if="list.length > 0">
            <template v-for="(item, i) in list">
              <tr v-bind:key="i">
                <td class="pl-0">
                  <a
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.name }}
                  </a>
                  <span
                    class="text-muted font-weight-bold text-muted d-block"
                    >{{ item.domain }}</span
                  >
                </td>

                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ item.provider.name || getName(item.provider.domain) }}
                  </a>
                  <span class="text-muted font-weight-bold text-muted d-block">
                    {{ item.provider.domain }}</span
                  >
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      v-model="item.description"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <select class="form-control" v-model="item.category._id">
                      <option
                        :value="category._id"
                        v-for="category in categories"
                        :key="category._id"
                        >{{ category.name }}</option
                      >
                    </select>
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control expiration"
                      v-model="item.expiration"
                      width="100px"
                    />
                  </div>
                </td>
                <td>
                  <div class="form-group">
                    <button
                      @click="removeCookie(i, item._id)"
                      class="btn btn-icon btn-light btn-hover-primary btn-sm"
                      content="<p><h6>Excluir Cookie</h6> Essa exclusão não removerá o cookie do seu site, apenas do nosso banner de consentimento.</p>"
                      v-tippy
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary"
                        ><i data-v-44bbc0be="" class="icon fas fa-trash"></i
                      ></span>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import cookiesModal from "@/view/pages/sites/modal/cookie";
import Swal from "sweetalert2";
export default {
  name: "sites-list",
  props: ["account"],
  data() {
    return {
      hash: "",
      categories: [],
      list: [],
      checked: false
    };
  },
  components: {
    cookiesModal
  },
  computed: {
    hashedItens() {
      return this.hashItens(this.list);
    },
    checkHashes() {
      return this.hashedItens === this.hash;
    },
    itemsToSave() {
      let toSave = {
        account: this.$route.params.id,
        status: true,
        cookies: []
      };
      this.list.forEach(cookie => {
        toSave.cookies.push({
          _id: cookie._id,
          name: cookie.name,
          description: cookie.description,
          expiration: cookie.expiration,
          provider: cookie.provider._id,
          category: cookie.category._id
        });
      });
      return toSave;
    }
  },
  mounted() {
    this.getCategories();
    this.getList();
  },
  methods: {
    removeCookie(index, _id) {
      Swal.fire({
        title: `<p>Você deseja realmente excluir o cookie: <br /> <br /> <b>${this.list[index].name}</b></p>`,
        showCancelButton: true,
        confirmButtonText: `Sim`,
        cancelButtonText: `Não`
      }).then(result => {
        if (result.isConfirmed) {
          ApiService.setHeader();
          ApiService.delete(`accountCookies/${_id}`).then(() => {
            this.list.splice(index, 1);
            Swal.fire("Cookie Removido com sucesso!", "", "success");
          });
        } else {
          Swal.fire("Nenhuma alteração foi realizada", "", "info");
        }
      });
    },
    getList(hash = true) {
      ApiService.setHeader();
      ApiService.query(`accountCookies/${this.$route.params.id}/full`)
        .then(({ data }) => {
          if (hash) {
            this.$set(this, "hash", this.hashItens(data.docs));
          }
          this.list = data.docs;
        })
        .catch(() => {
          //console.log("LIST ERROR", response);
        });
    },
    getCategories() {
      ApiService.setHeader();
      ApiService.query(`categories/full`)
        .then(({ data }) => {
          this.categories = data.docs;
        })
        .catch(() => {
          //console.log("LIST ERROR", response);
        });
    },
    hashItens(items) {
      return new Buffer.from(JSON.stringify(items, "", 2)).toString("base64");
    },
    getName(domain) {
      let splited = domain.split(".");
      if (splited[0] !== "") return splited[0];
      return splited[1];
    },
    saveItems() {
      if (!this.account.steps.cookies) {
        this.account.steps.cookies = true;
        ApiService.put(`account/${this.account._id}`, this.account).then(
          result => {
            console.log(result);
          }
        );
      }
      ApiService.setHeader();
      ApiService.post(`cookies`, this.itemsToSave)
        .then(() => {
          Swal.fire({
            title: "Deu Certo!",
            text: "Cookies foram registrados e publicados com sucesso!",
            icon: "success",
            heightAuto: false
          }).then(() => {
            this.$router.push(`/sites/${this.$route.params.id}/details`);
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            text: "Ocorreu algum erro durante o registro.",
            icon: "error",
            heightAuto: false
          });
        });
    }
  }
};
</script>
<style scoped>
.action-disabled {
  cursor: no-drop;
}
.expiration {
  width: 150px !important;
}
</style>
