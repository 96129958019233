<template>
  <div>
    <b-button
      class="btn font-weight-bolder font-size-sm py-3 px-14 btn-secondary text-uppercase mr-5"
      v-b-modal.account-cookie-modal
      >Adicionar Cookie</b-button
    >
    <b-modal
      ref="account-cookie-modal"
      size="lg"
      centered
      hide-footer
      id="account-cookie-modal"
      :title="title"
    >
      <form class="form">
        <div class="card-body">
          <div class="form-group">
            <h5 class="font-weight-bold mb-6">*Nome do Cookie</h5>
            <input
              type="name"
              class="form-control form-control-solid"
              placeholder="Cookie"
              v-model="cookie.name"
              required
            />
          </div>
          <div class="form-group">
            <h5 class="font-weight-bold mb-6">*Domínio</h5>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder=".site.com.br"
              v-model="cookie.domain"
              required
            />
            <span class="form-text text-muted"
              >Incluir apenas o domínio como nos exemplos: <b>.consensu.io</b> e
              <b>.google.com</b>
            </span>
          </div>

          <div class="form-group">
            <h5 class="font-weight-bold mb-6">*Categoria</h5>
            <!-- <input
              type="text"
              class="form-control form-control-solid"
              placeholder=".site.com.br"
              v-model="cookie.category"
              required
            /> -->

            <select class="form-control" v-model="cookie.category">
              <option
                :value="category._id"
                v-for="category in categories"
                :key="category._id"
                >{{ category.name }}</option
              >
            </select>
          </div>

          <div class="form-group">
            <h5 class="font-weight-bold mb-6">Expiração</h5>
            <input
              type="text"
              class="form-control form-control-solid"
              placeholder="1 ano"
              v-model="cookie.expiration"
              required
            />
          </div>
          <span class="form-text text-muted"
            >Campos com (*) são obrigatórios
          </span>
        </div>
        <div class="card-footer">
          <button type="button" class="btn btn-primary mr-2" @click="handleOk">
            Salvar
          </button>
          <button type="button" class="btn btn-secondary" @click="hideModal">
            Cancelar
          </button>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
export default {
  name: "account-modal",
  props: ["account", "categories", "callback"],
  data() {
    return {
      cookie: {
        name: "",
        domain: "",
        category: "",
        expiration: ""
      }
    };
  },
  mounted() {},
  computed: {
    valid() {
      return (
        this.cookie.name != "" &&
        this.cookie.domain != "" &&
        this.cookie.category != ""
      );
    },
    title() {
      return `Adicionando cookie ao site: ${this.account.website}`;
    }
  },
  methods: {
    showModal() {
      this.$refs["account-cookie-modal"].show();
    },
    hideModal() {
      this.$refs["account-cookie-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["account-cookie-modal"].toggle("#toggle-btn");
    },
    handleOk(event) {
      event.preventDefault();
      if (this.valid) {
        this.create();
      } else {
        Swal.fire({
          title: "Ops!",
          text: "Você precisa preencher os campos obrigatórios",
          icon: "warning",
          heightAuto: false
        });
      }
    },
    create() {
      ApiService.post(`cookies/${this.account._id}/create`, {
        cookie: this.cookie,
        provider: this.provider
      })
        .then(result => {
          this.$set(this.account.stats, "cookies", result.data.total);
          this.$bvModal.hide("account-cookie-modal");
          Swal.fire({
            title: "Deu Certo!",
            text: "Site cadastrado com sucesso!",
            icon: "success",
            heightAuto: false
          }).then(() => {
            this.callback(false);
          });
        })
        .catch(response => {
          Swal.fire({
            title: "Ops!",
            text:
              "Tivemos algum problema, caso o mesmo persista,por favor informar nosso deparmento técnico.",
            icon: "error",
            heightAuto: false
          });
          console.log("ERROR", response);
        });
    }
  }
};
</script>

<style scoped>
select.form-control {
  background-color: #f3f6f9;
  border-color: #f3f6f9;
}
</style>
